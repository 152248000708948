<template>
  <DelayHydration>
    <component
      :is="frontendCeLayout"
      :uid="uid"
      :index="index"
      :appearance="appearance"
      :header="header"
      :subheader="subheader"
      :header-layout="headerLayout"
      :header-size="headerSize"
      :header-position="headerPosition"
      :header-link="headerLink"
      :theme-color="theme"
      :lang="currentLocale"
      class="t3-ce-header"
    />
  </DelayHydration>
</template>

<script setup lang="ts">
import { computed, defineAsyncComponent } from 'vue'

import { useCurrentLocale } from '#imports'

import type { T3CeHeaderProps } from '~/types'

const props = withDefaults(defineProps<T3CeHeaderProps>(), {
  header: '',
  subheader: '',
  headerLayout: 1,
  headerSize: 1,
  headerPosition: '',
  headerLink: '',
  theme: 'default'
})

const currentLocale = useCurrentLocale()

const UiHeader = defineAsyncComponent(
  () => import('~ui/components/Ui/UiHeader/UiHeader.vue')
)

const frontendCeLayout = computed(() => {
  switch (props.appearance?.layout) {
    default:
      return UiHeader
  }
})
</script>
